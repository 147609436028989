import { MutableRefObject } from 'react';
import axios from 'axios';
import { ApiError } from 'entities/ApiError.entity';
import { Chat } from 'entities/Chat.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { Message } from 'entities/Message.entity';
import { FeedbackTypes, MessageTypes } from 'enums';
import { ChatsApi, GetChatsQuery } from 'services/API/Chats/ChatsApi';

export const fetchMessagesByChatId = async (
  chatId: string
): Promise<Message[]> => {
  try {
    const response = await ChatsApi.fetchMessagesByChatId(chatId);

    return response.data.map(Message.deserialize).reverse();
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchChats = async (
  chatsQuery?: GetChatsQuery
): Promise<Chat[]> => {
  try {
    const response = await ChatsApi.fetchChats(chatsQuery);

    return response.data.map(Chat.deserialize);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchChatDetailsById = async (chatId: string): Promise<Chat> => {
  try {
    const response = await ChatsApi.fetchChatDetailsById(chatId);

    return Chat.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchChatById = async (chatId: string): Promise<Chat> => {
  try {
    const response = await ChatsApi.fetchChatById(chatId);

    return Chat.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const sendMessage = async (
  chatId: string,
  message: string,
  abortController: MutableRefObject<AbortController | null>,
  setMessageData: (data: {
    id?: string;
    messageId?: string;
    type: MessageTypes;
    statement: string;
  }) => void
): Promise<{
  id: string;
  type: MessageTypes;
  messageId: string;
  statement: string;
  dashboardId: string;
} | void> => {
  try {
    const response = await ChatsApi.createMessage(
      chatId,
      message,
      abortController,
      setMessageData
    );

    const id = response.headers.answerid;
    const messageId = response.headers.messageid;
    const statement = response.data.replace(/None$/g, '');
    const { dashboardid } = response.headers;

    return {
      id,
      messageId,
      statement,
      dashboardId: dashboardid,
      type: MessageTypes.answer
    };
  } catch (e) {
    if (axios.isCancel(e)) return;

    try {
      // @ts-expect-error for now
      e.response.data = JSON.parse(e.response.data);
      // @ts-expect-error for now
      throw ApiError.deserializeFromCatch(e);
    } catch {
      // @ts-expect-error for now
      throw ApiError.deserializeFromCatch(e);
    }
  }
};

export const updateChatWidget = async ({
  chatId,
  messageId,
  widgetId,
  data
}: {
  chatId: string;
  messageId: string;
  widgetId: DashboardComponent['id'];
  data: { isHidden: boolean };
}): Promise<void> => {
  try {
    await ChatsApi.updateChatWidget({
      chatId,
      messageId,
      widgetId,
      data
    });
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const regenerateChatMessage = async (
  chatId: string,
  messageId: string,
  abortController: MutableRefObject<AbortController | null>,
  setMessageData: (data: {
    id?: string;
    messageId?: string;
    type: MessageTypes;
    statement: string;
  }) => void
): Promise<{
  id: string;
  type: MessageTypes;
  messageId: string;
  statement: string;
  dashboardId: string;
} | void> => {
  try {
    const response = await ChatsApi.regenerateChatMessage(
      chatId,
      messageId,
      abortController,
      setMessageData
    );

    const id = response.headers.answerid;
    const statement = response.data.replace(/None$/g, '');
    const { dashboardid } = response.headers;

    return {
      id,
      messageId,
      statement,
      dashboardId: dashboardid,
      type: MessageTypes.answer
    };
  } catch (e) {
    if (axios.isCancel(e)) return;

    try {
      // @ts-expect-error for now
      e.response.data = JSON.parse(e.response.data);
      // @ts-expect-error for now
      throw ApiError.deserializeFromCatch(e);
    } catch {
      // @ts-expect-error for now
      throw ApiError.deserializeFromCatch(e);
    }
  }
};

export const createChat = async ({
  title,
  subject
}: {
  title?: string;
  subject?: string;
}): Promise<Chat> => {
  try {
    const response = await ChatsApi.createChat({ subject, title });

    return Chat.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const archiveChat = async (
  chatId: string,
  data: { isArchived: boolean }
): Promise<Chat> => {
  try {
    const response = await ChatsApi.archiveChat(chatId, data);

    return Chat.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateChat = async (
  chatId: string,
  data: { title: string }
): Promise<Chat> => {
  try {
    const response = await ChatsApi.updateChat(chatId, data);

    return Chat.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const rateChatMessage = async ({
  text,
  chatId,
  messageId,
  feedbackType
}: {
  text?: string;
  chatId: string;
  messageId: string;
  feedbackType: FeedbackTypes;
}): Promise<void> => {
  try {
    await ChatsApi.rateChatMessage({
      text,
      chatId,
      messageId,
      feedbackType
    });
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const getFollowUpQuestions = async (
  chatId: string,
  messageId: string,
  signal: AbortSignal,
  setMessageData: (data: {
    id?: string;
    messageId?: string;
    type: MessageTypes;
    statement: string;
  }) => void
): Promise<{
  id: string;
  messageId: string;
  type: MessageTypes;
  statement: string;
} | null> => {
  try {
    const response = await ChatsApi.getFollowUpQuestions(
      chatId,
      messageId,
      signal,
      setMessageData
    );

    const id = response.headers.followupid;
    const streamMessageId = response.headers.messageid;
    const statement = response.data.replace(/None$/g, '');

    return {
      id,
      messageId: streamMessageId,
      statement,
      type: MessageTypes.followup_questions
    };
  } catch {
    return null;
  }
};

export const requestProductsData = async (
  payload: URLSearchParams
): Promise<void> => {
  try {
    await ChatsApi.requestProductsData(payload);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};
