import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Backdrop, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as InfoIcon } from 'assets/InfoIcon.svg';
import cn from 'classnames';
import { CommonTooltip, WidgetEmptyState } from 'components';
import { DashboardFilterModal } from 'components/DashboardFilterModal';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { InsightsType, queryKeys } from 'enums';
import {
  useCreatedNestedDashboard,
  useUserCoins,
  useUserInfo
} from 'hooks/api';
import { useChatById } from 'hooks/api/useChatById';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { ZoomableSunburst } from './ZoomableSunburst';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  data?: DashboardParams;
}

export const ZoomableSunburstChart: FC<Props> = ({ data, className }) => {
  const { t } = useTranslation();
  const { chatId, dashboardId } = useParams();
  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();
  const [isApplyFilterModalOpen, setApplyFilterModalOpen] = useState(false);

  const zoomableSunburstContainerRef = useRef<HTMLDivElement | null>(null);

  const [selectedItem, setSelectedItem] = useState<{
    name: string;
    code: string;
  } | null>(null);

  const { mutate: fetchChatData } = useChatById();

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId!)
  });

  const onSuccess = () => {
    if (dashboardData?.chatId) {
      fetchChatData({ chatId: dashboardData.chatId });
    }
  };

  const { mutate: createNestedDashboard, isPending } =
    useCreatedNestedDashboard(onSuccess);

  const selectItem = (data: { name: string; code: string } | null) => {
    setSelectedItem(data);
  };

  const sendMixpanelEvent = (mixpanelEvent: MixpanelEvents) => {
    mixpanel?.track(mixpanelEvent, {
      'Dashboard ID': dashboardId,
      // eslint-disable-next-line no-restricted-globals
      'Dashboard link': `${location.origin}/chats/${chatId}/dashboards/${dashboardId}`
    });
  };

  const onApplyFilter = () => {
    createNestedDashboard({
      dashboardId: dashboardId || '',
      chatId: chatId || '',
      originDashboardId: dashboardData?.originDashboardId,
      data: {
        type: InsightsType.HierarchyIndustries,
        value: selectedItem?.code || ''
      }
    });
  };

  const handleApplyFilterModalClose = () => {
    sendMixpanelEvent(MixpanelEvents.CancelFilterClick);

    setApplyFilterModalOpen(false);
  };
  const handleApplyFilterModalConfirm = () => {
    sendMixpanelEvent(MixpanelEvents.ConfirmFilterClick);

    setApplyFilterModalOpen(false);
    onApplyFilter();
  };

  const hasCoins = userInfo?.isPremiumUser ? true : userCoins?.count;

  const handleFilterButtonClick = () => {
    sendMixpanelEvent(MixpanelEvents.FilterClick);

    if (!selectedItem || !hasCoins) return null;

    if (userInfo?.isFreeUser) {
      setApplyFilterModalOpen(true);
    } else {
      onApplyFilter();
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <DashboardFilterModal
        isOpen={isApplyFilterModalOpen}
        industry={selectedItem?.name || ''}
        onClose={handleApplyFilterModalClose}
        onConfirm={handleApplyFilterModalConfirm}
      />

      {isPending && (
        <Backdrop className={styles.overlay} open={isPending}>
          <div className={styles['overlay-content']}>
            <span className={styles.text}>{t('Common.NewDashboard')}</span>
            <div className={styles.spinner} />
          </div>
        </Backdrop>
      )}

      <div className={styles.header}>
        <div className={styles['filter-wrapper']}>
          <div className={cn(styles.filter)}>
            <CommonTooltip
              title={
                selectedItem?.name ||
                t('Page.Dashboard.ZoomableSunburst.ChooseFilter')
              }
              className={cn(
                styles.title,
                'overflowed-text',
                selectedItem && styles['has-value']
              )}
            />
          </div>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={handleFilterButtonClick}
            disabled={!selectedItem || !hasCoins || !!userInfo?.deactivatedAt}
          >
            {t('Common.ApplyFilter')}
          </Button>
        </div>
        <div className={styles.note}>
          <InfoIcon />

          {selectedItem
            ? t('Page.Dashboard.ZoomableSunburst.NoteActive', {
                industry: selectedItem.name
              })
            : t('Page.Dashboard.ZoomableSunburst.Note')}
        </div>
      </div>
      {data?.data?.length ? (
        <div
          ref={zoomableSunburstContainerRef}
          className={styles['zoomable-sunburst-chart-container']}
        >
          <ZoomableSunburst data={data.data[0]} selectItem={selectItem} />
        </div>
      ) : (
        <WidgetEmptyState />
      )}
    </div>
  );
};
