import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as FacebookIcon } from 'assets/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'assets/linkedin.svg';
import { ReactComponent as YoutubeIcon } from 'assets/youtube.svg';
import cn from 'classnames';
import { useContactGieniUrl, useLandingUrl } from 'hooks';

import styles from './styles.module.scss';

const footerSocials = [
  {
    link: 'https://www.linkedin.com/company/gieniai',
    icon: <LinkedinIcon className={styles.icon} />
  },
  {
    link: 'https://www.youtube.com/@GieniAI',
    icon: <YoutubeIcon className={styles.icon} />
  },
  {
    link: 'https://www.facebook.com/GieniAI',
    icon: <FacebookIcon className={styles.icon} />
  }
];

interface Props {
  className?: string;
}

export const Footer: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const landingUrl = useLandingUrl();
  const contactGieniUrl = useContactGieniUrl();

  return (
    <footer className={cn(styles.footer, className)}>
      <div className={styles.links}>
        <div className={styles.buttons}>
          {footerSocials.map((social) => (
            <Link
              target="_blank"
              to={social.link}
              key={social.link}
              className={styles.button}
            >
              {social.icon}
            </Link>
          ))}
        </div>
        <div className={styles.technical}>
          <Link
            target="_blank"
            className={styles.link}
            to={`${landingUrl}/terms-and-conditions`}
          >
            {t('Common.TermsAndConditions')}
          </Link>
          <Link
            target="_blank"
            className={styles.link}
            to={`${landingUrl}/privacy-policy`}
          >
            {t('Common.PrivacyPolicy')}
          </Link>
        </div>
      </div>
      <div className={styles.info}>
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          href={contactGieniUrl}
          className={styles['contact-button']}
        >
          {t('Common.ContactGieni')}
        </Button>
        <span className={styles.year}>
          {t('Common.RightsReserved', {
            currentYear: new Date().getFullYear()
          })}
        </span>
      </div>
    </footer>
  );
};
