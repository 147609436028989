import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { CommonTooltip, WidgetEmptyState } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { queryKeys } from 'enums';
import { Routes } from 'enums/Routes';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  data?: DashboardParams;
}

export const DashboardHistory: FC<Props> = ({ data, className }) => {
  const navigate = useNavigate();
  const { dashboardId, chatId } = useParams();
  const { t } = useTranslation();

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId!)
  });

  const messages = useMemo(() => [...(data?.data || [])], [data?.data]);

  const goToChat = () => {
    navigate(Routes.NewChat);

    mixpanel?.track(MixpanelEvents.NewChatClick, {
      'Dashboard ID': dashboardId,
      // eslint-disable-next-line no-restricted-globals
      'Dashboard link': `${location.origin}/chats/${chatId}/dashboards/${dashboardId}`
    });
  };

  if (!messages.length) {
    return (
      <div className={styles['empty-state']}>
        {t('Page.Dashboard.DashboardHistory.NoMessages')}
      </div>
    );
  }

  const parentDashboard = (data?.data || []).find(
    ({ id }) => id === dashboardData?.originDashboardId
  );

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.header}>
        <CommonTooltip
          title={
            parentDashboard?.title || t('Page.Dashboard.ZoomableSunburst.Title')
          }
          className={cn(styles.title, 'overflowed-text')}
        />
      </div>
      {messages?.length ? (
        <div className={styles.messages}>
          {messages.map((message) => (
            <div
              className={cn(
                styles.message,
                message.id === dashboardData?.id && styles.active
              )}
              key={message.title}
              onClick={() =>
                navigate(
                  Routes.Dashboard.replace(
                    ':dashboardId',
                    message.id || ''
                  ).replace(':chatId', chatId || '')
                )
              }
            >
              {message.title}
            </div>
          ))}
        </div>
      ) : (
        <WidgetEmptyState />
      )}

      <div className={styles.footer}>
        <Button
          fullWidth
          size="medium"
          color="primary"
          variant="outlined"
          onClick={goToChat}
        >
          {t('Page.Dashboard.DashboardHistory.CreateNewChat')}
        </Button>
      </div>
    </div>
  );
};
