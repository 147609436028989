import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import cn from 'classnames';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { MoreDataModal } from 'views/Conversation/MoreDataModal';

import styles from './styles.module.scss';

export const CRMBoosterAd = () => {
  const { t } = useTranslation();
  const { dashboardId, chatId } = useParams();

  const [isMoreDataModalOpen, setMoreDataModalOpen] = useState(false);

  const defaultSelectedProducts = [
    t('Common.MoreDataModal.Products.CRMBooster')
  ];

  const handleCtaClick = () => {
    setMoreDataModalOpen(true);

    mixpanel?.track(MixpanelEvents.CRMBoosterRequested, {
      'Dashboard ID': dashboardId,
      // eslint-disable-next-line no-restricted-globals
      'Dashboard link': `${location.origin}/chats/${chatId}/dashboards/${dashboardId}`
    });
  };

  const handleCloseModal = () => {
    setMoreDataModalOpen(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>
          <h2 className={styles.title}>
            {t('Page.Dashboard.CRMBoosterAd.Title')}
          </h2>
          <span className={styles.subtitle}>
            {t('Page.Dashboard.CRMBoosterAd.Subtitle')}
          </span>
        </div>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleCtaClick}
          className={cn(styles.cta, 'brilliance')}
        >
          {t('Page.Dashboard.CRMBoosterAd.Cta')}
        </Button>
      </div>

      <MoreDataModal
        isOpen={isMoreDataModalOpen}
        onClose={handleCloseModal}
        defaultSelectedProducts={defaultSelectedProducts}
      />
    </>
  );
};
